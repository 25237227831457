import React from "react";
import { Link } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <img
          alt="Ghost getting abducted by aliens"
          className="block mx-auto w-1/2"
          src={abductionIllustration}
        />
        <h2 className="w-full flex flex-col text-2xl text-center font-bold inline-block p-3 pb-0">
          <span>¿Te perdiste? </span> <span>¡Te ayudamos a regresar!</span>
        </h2>

        <Link to={'/'} className="flex justify-center items-center pb-10">
          <button className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-3 py-4 px-8 shadow-lg">Ir a la página de inicio</button>
        </Link>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
